import * as React from 'react'
import { Translation } from '../types/main'
import { translations } from '../translations'
import AboutContainer from '../containers/about'
import Seo from '../components/seo'

const t: Translation = {
  language: 'en',
  translations: translations.en
}

const IndexPage = () => (
  <>
    <Seo title="About us" lang="en" />
    <AboutContainer t={t} />
  </>
)

export default IndexPage
